import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { compact, get, find } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ProductPage from '../components/products/ProductPage';
import ProductsHeader from '../components/ProductsHeader';
import withRedirectServerData from '../components/withRedirectServerData';
import fetchProductsNoSSR from '../../integration/fetchProductsNoSSR';
import SEOHelmet from '../components/SEOHelmet';

const ProductSearches = (props) => {
  const [matched, setMatch] = useState();
  // eslint-disable-next-line
  const v = props['*'];

  const data = useStaticQuery(graphql`
    {
      allContentfulProductBucket {
        nodes {
          name
          descriptionRt {
            raw
          }
          photo {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid
            }
          }
          slug
          seo {
            title
            metaDescription {
              metaDescription
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setMatch(
      find(
        get(data, 'allContentfulProductBucket.nodes'),
        // eslint-disable-next-line
        (item) => item.slug === v,
      ),
    );
  }, [v]);

  const [loading, setLoading] = useState(true);
  const [listResult, setListResult] = useState({});

  useEffect(async () => {
    setLoading(true);
    const p = await fetchProductsNoSSR('searchSlug')({
      paramInfo: {},
      location: get(props, 'location', {}),
    });
    setListResult(p);
    setLoading(false);
  }, [props]);

  return (
    <ProductsHeader
      title={matched?.name}
      description={matched?.descriptionRt?.raw}
      image={matched?.photo}
    >
      <SEOHelmet
        title={compact([
          get(matched, 'seo.title'),
          'Products',
        ]).join(' | ')}
        description={get(
          matched,
          'seo.metaDescription.metaDescription',
        )}
      />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '50vh',
            justifyContent: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ProductPage {...listResult?.data} />
      )}
    </ProductsHeader>
  );
};

ProductSearches.propTypes = {
  searchSlug: PropTypes.string.isRequired,
  serverData: PropTypes.shape({
    // eslint-disable-next-line
    data: PropTypes.object,
  }).isRequired,
};

export default ProductSearches;

/* export default withRedirectServerData(ProductSearches, {
  destination: '/products',
}); */

/* export async function getServerData(req) {
  return fetchProducts('searchSlug')(req);
} */
